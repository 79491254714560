import React, {useContext, useState} from 'react';
import { Trans, I18nextContext } from "gatsby-plugin-react-i18next";
import { env } from '../../app.constant';
import { PrecontractualPopup } from '../product/PrecontractualPopup';
export default function FaqComponent() {
    const { language } = useContext(I18nextContext)
    const [getId, setId] = useState("")
    const [showPrecontract, setPrecontract] = useState(false)
    const [showDisplayPopUp, setDisplayPopUp] = useState("tnc")

    const handleTncClick = (param) => {
        setDisplayPopUp(param)
        setPrecontract(true)
    }

    function toggleQue (id) {
        document.getElementById(id).classList.toggle('active');
        if(getId===""){
            setId(id)
        }else{
            setId("")
        }
    }
        return (
            <div className="faq-page">
             <PrecontractualPopup
                target={showDisplayPopUp}
                show={showPrecontract}
                close={() => setPrecontract(false)}
             />
             <div className="container">
                <div className="top5-questions">
                    <h2>FAQs</h2>
                    <ul>
                        <li id="que1">
                            <h3 className={getId!==""&&getId==="que1"?"que":"que border-bottom-line"} onClick={() => toggleQue('que1')}><Trans>faq.tab1.title</Trans></h3>
                            <div className="ans">
                                <p><Trans>faq.tab1.description1</Trans></p>
                                <p><Trans>faq.tab1.s1</Trans><br/><Trans>faq.tab1.s2</Trans></p>
                                <p><Trans>faq.tab1.description2</Trans></p>
                                <p><Trans components={{
                                    1: (
                                        <a
                                            href="javascript:void(0)"
                                            tabIndex="-1"
                                            onClick={() => handleTncClick("tnc")}
                                        />
                                    )
                                    }}>faq.tab1.description3</Trans></p>
                            </div>
                        </li>
                        <li id="que2">
                            <h3 className={getId!==""&&getId==="que2"?"que":"que border-bottom-line"} onClick={() => toggleQue('que2')}><Trans>faq.tab2.title</Trans></h3>
                            <div className="ans">
                                <p><Trans>faq.tab2.description1</Trans></p>
                                <p><Trans>faq.tab2.s1</Trans><br/><Trans>faq.tab2.s2</Trans></p>
                                <p><Trans components={{
                                    1: (
                                        <a
                                            href="javascript:void(0)"
                                            tabIndex="-1"
                                            onClick={() => handleTncClick("tnc")}
                                        />
                                    )
                                    }}>faq.tab2.description2</Trans></p>
                            </div>
                        </li>
                        <li id="que3">
                            <h3 className={getId!==""&&getId==="que3"?"que":"que border-bottom-line"} onClick={() => toggleQue('que3')}><Trans>faq.tab3.title</Trans></h3>
                            <div className="ans">
                                <p><Trans>faq.tab3.description</Trans></p>
                            </div>
                        </li>
                        <li id="que4">
                            <h3 className={getId!==""&&getId==="que4"?"que":"que border-bottom-line"} onClick={() => toggleQue('que4')}><Trans>faq.tab4.title</Trans></h3>
                            <div className="ans">
                                <p><Trans>faq.tab4.description1</Trans>
                                    {env.GATSBY_COUNTRY === 'BE' && 
                                        <a href="https://be.careplus.co" target="_blank" rel="noreferrer" style={{color:"blue"}}>
                                            https://be.careplus.co
                                        </a>
                                    }
                                    {env.GATSBY_COUNTRY === 'NL' && 
                                        <a href="https://nl.careplus.co" target="_blank" rel="noreferrer" style={{color:"blue"}}>
                                            https://nl.careplus.co
                                        </a>
                                    }
                                    {env.GATSBY_COUNTRY === 'FI' && 
                                        <a href="https://fi.careplus.co" target="_blank" rel="noreferrer" style={{color:"blue"}}>
                                            https://fi.careplus.co
                                        </a>
                                    }
                                </p>
                                <p><Trans>faq.tab4.description2</Trans></p>
                                <p><Trans>faq.tab4.description3</Trans>
                                    {language === "en" && env.GATSBY_COUNTRY === 'BE' && 
                                        "+3278079260"
                                    }
                                    {language === "en" && env.GATSBY_COUNTRY === 'NL' && 
                                        "+31858881836"
                                    }
                                    {language === "en" && env.GATSBY_COUNTRY === 'FI' && 
                                        "+358753254781"
                                    }
                                </p>
                            </div>
                        </li>
                        <li id="que5">
                            <h3 className={getId!==""&&getId==="que5"?"que":"que border-bottom-line"} onClick={() => toggleQue('que5')}><Trans>faq.tab5.title</Trans></h3>
                            <div className="ans">
                                <p><Trans>faq.tab5.description1</Trans></p>
                                <Trans
                                    components={{
                                        1: (
                                            <a
                                                href="https://www.samsung.com/be/offer/samsung-care-plus/"
                                                tabIndex="-1"
                                            />
                                        ),
                                        2: (
                                            <a
                                                href="https://www.samsung.com/nl/offer/samsung-care-plus/"
                                                tabIndex="-1"
                                            />
                                        ),
                                        3: (
                                            <a
                                                href="https://www.samsung.com/fi/offer/samsung-care-plus/"
                                                tabIndex="-1"
                                            />
                                        ),
                                    }}
                                >
                                    faq.tab5.description2
                                </Trans>
                            </div>
                        </li>
                        <li id="que6">
                            <h3 className={getId!==""&&getId==="que6"?"que":"que border-bottom-line"} onClick={() => toggleQue('que6')}><Trans>faq.tab6.title</Trans></h3>
                            <div className="ans">
                                <p><Trans>faq.tab6.description</Trans></p>
                            </div>
                        </li>
                        <li id="que7">
                            <h3 className={getId!==""&&getId==="que7"?"que":"que border-bottom-line"} onClick={() => toggleQue('que7')}><Trans>faq.tab7.title</Trans></h3>
                            <div className="ans">
                                <p><Trans>faq.tab7.description1</Trans></p>
                                <p><Trans>faq.tab7.description2</Trans></p>
                            </div>
                        </li>
                        <li id="que8">
                            <h3 className={getId!==""&&getId==="que8"?"que":"que border-bottom-line"} onClick={() => toggleQue('que8')}><Trans>faq.tab8.title</Trans></h3>
                            <div className="ans">
                                <p><Trans>faq.tab8.description</Trans></p>
                            </div>
                        </li>
                        <li id="que9">
                            <h3 className={getId!==""&&getId==="que9"?"que":"que border-bottom-line"} onClick={() => toggleQue('que9')}><Trans>faq.tab9.title</Trans></h3>
                            <div className="ans">
                                <p><Trans>faq.tab9.description1</Trans></p>
                                <p><Trans>faq.tab9.description2</Trans></p>
                            </div>
                        </li>
                        <li id="que10">
                            <h3 className={getId!==""&&getId==="que10"?"que":"que border-bottom-line"} onClick={() => toggleQue('que10')}><Trans>faq.tab10.title</Trans></h3>
                            <div className="ans">
                                <p><Trans>faq.tab10.description</Trans></p>
                            </div>
                        </li>
                        <li id="que11">
                            <h3 className={getId!==""&&getId==="que11"?"que":"que border-bottom-line"} onClick={() => toggleQue('que11')}><Trans>faq.tab11.title</Trans></h3>
                            <div className="ans">
                                <p>
                                    <Trans>faq.tab11.description1</Trans>
                                    <a href="https://www.samsung.com/my/" target="_blank" rel="noreferrer" style={{color:"blue"}}>Samsung.com</a>
                                    <Trans>faq.tab11.description2</Trans>
                                </p>
                                {env.GATSBY_COUNTRY !== 'FI' && <p>
                                    <Trans>faq.tab11.description3</Trans>
                                    <a href="http://Samsungcareplus.com" target="_blank" rel="noreferrer" style={{color:"blue"}}>Samsungcareplus.com</a>
                                    <Trans>faq.tab11.description4</Trans>
                                </p>}
                            </div>
                        </li>
                        <li id="que12">
                            <h3 className={getId!==""&&getId==="que12"?"que":"que border-bottom-line"} onClick={() => toggleQue('que12')}><Trans>faq.tab12.title</Trans></h3>
                            <div className="ans">
                                <p><Trans>faq.tab12.description</Trans></p>
                            </div>
                        </li>
                        <li id="que13">
                            <h3 className={getId!==""&&getId==="que13"?"que":"que border-bottom-line"} onClick={() => toggleQue('que13')}><Trans>faq.tab13.title</Trans></h3>
                            <div className="ans">
                                <ol>
                                    <li><Trans components={{ 1: <strong /> }}>faq.tab13.imei.method.s1</Trans></li>
                                    <li><Trans components={{ 1: <strong /> }}>faq.tab13.imei.method.s2</Trans></li>
                                    <li><Trans components={{ 1: <strong /> }}>faq.tab13.imei.method.s3</Trans></li>
                                    <li><Trans>faq.tab13.imei.method.s4</Trans></li>
                                    <li><Trans>faq.tab13.imei.method.s5</Trans></li>
                                </ol>
                                <br/>
                                <h4><b><Trans>faq.tab13.serial.method.title</Trans></b></h4>
                                <h5 className="padding-top-bottom-8"><b><Trans>faq.tab13.serial.method.subTitle1</Trans></b></h5>
                                <ol>
                                    <li><Trans>faq.tab13.serial.method.s1Laptop</Trans></li>
                                    <li><Trans components={{ 1: <strong /> }}>faq.tab13.serial.method.s2Laptop</Trans></li>
                                    <li><Trans components={{ 1: <strong /> }}>faq.tab13.serial.method.s3Laptop</Trans></li>
                                    <li><Trans>faq.tab13.serial.method.s4Laptop</Trans></li>
                                </ol>
                                <br/>
                                <h5 className="padding-top-bottom-8"><b><Trans>faq.tab13.serial.method.subTitle2</Trans></b></h5>
                                <ol>
                                    <li><Trans components={{ 1: <strong /> }}>faq.tab13.serial.method.s1Tablet</Trans></li>
                                    <li><Trans components={{ 1: <strong /> }}>faq.tab13.serial.method.s2Tablet</Trans></li>
                                    <li><Trans>faq.tab13.serial.method.s3Tablet</Trans></li>
                                </ol>
                            </div>
                        </li>
                        <li id="que14">
                            <h3 className={getId!==""&&getId==="que14"?"que":"que border-bottom-line"} onClick={() => toggleQue('que14')}><Trans>faq.tab14.title</Trans></h3>
                            <div className="ans">
                                <p><Trans>faq.tab14.description</Trans></p>
                            </div>
                        </li>
                        {env.GATSBY_COUNTRY !== 'FI' && (
                            <li id="que15">
                                <h3 className={getId!==""&&getId==="que15"?"que":"que border-bottom-line"} onClick={() => toggleQue('que15')}><Trans>faq.tab15.title</Trans></h3>
                                <div className="ans">
                                    <p><Trans>faq.tab15.description</Trans></p>
                                </div>
                            </li>
                        )}
                        {env.GATSBY_COUNTRY !== 'FI' && (
                            <li id="que16">
                                <h3 className={getId!==""&&getId==="que16"?"que":"que border-bottom-line"} onClick={() => toggleQue('que16')}><Trans>faq.tab16.title</Trans></h3>
                                <div className="ans">
                                    <p><Trans>faq.tab16.description1</Trans></p>
                                    <p><Trans>faq.tab16.description2</Trans></p>
                                {(language === "nl_nl" || language === "be_nl" || language === "be_fr")
                                && (
                                    <>
                                    <p><Trans>faq.tab16.description3</Trans></p>
                                    <p><Trans>faq.tab16.description4</Trans></p>
                                    </>
                                )}
                                </div>
                            </li>
                        )}
                        {env.GATSBY_COUNTRY === 'FI' && (
                            <li id="que16">
                                <h3 className={getId!==""&&getId==="que16"?"que":"que border-bottom-line"} onClick={() => toggleQue('que16')}><Trans>faq.tab16-fi.title</Trans></h3>
                                <div className="ans">
                                    <p><Trans>faq.tab16-fi.description1</Trans></p>
                                    <p><Trans>faq.tab16-fi.description2</Trans></p>
                                </div>
                            </li>
                        )}
                        <li id="que17">
                            <h3 className={getId!==""&&getId==="que17"?"que":"que border-bottom-line"} onClick={() => toggleQue('que17')}><Trans>faq.tab17.title</Trans></h3>
                            <div className="ans">
                                <p><Trans>faq.tab17.description</Trans></p>
                                <p>
                                    <Trans>faq.tab17.link</Trans>
                                    {env.GATSBY_COUNTRY === 'BE' && (
                                        <a href="https://www.samsung.com/be/offer/samsung-care-plus/" target="_blank" rel="noreferrer" style={{color:"blue", textDecoration:"none"}}><Trans>faq.tab17.site</Trans></a>
                                    )}
                                    {env.GATSBY_COUNTRY === 'NL' && (
                                        <a href="https://www.samsung.com/nl/offer/samsung-care-plus/" target="_blank" rel="noreferrer" style={{color:"blue", textDecoration:"none"}}><Trans>faq.tab17.site</Trans></a>
                                    )}
                                    {env.GATSBY_COUNTRY === 'FI' && (
                                        <a href="https://www.samsung.com/fi/offer/samsung-care-plus/" target="_blank" rel="noreferrer" style={{color:"blue", textDecoration:"none"}}><Trans>faq.tab17.site</Trans></a>
                                    )}
                                    </p>
                            </div>
                        </li>
                        <li id="que18">
                            <h3 className={getId!==""&&getId==="que18"?"que":"que border-bottom-line"} onClick={() => toggleQue('que18')}><Trans>faq.tab18.title</Trans></h3>
                            <div className="ans">
                                <p>
                                    <Trans>faq.tab18.description1</Trans>
                                    <a href="https://amtrustinternational.com/about-us/underwriting/" target="_blank" rel="noreferrer" style={{color:"blue"}}>https://amtrustinternational.com/about-us/underwriting/</a>
                                    <Trans>faq.tab18.description2</Trans>
                                    <a href="mailto:enquiries@centralbank.ie" style={{color:"blue"}}>enquiries@centralbank.ie</a>
                                    ,&nbsp;
                                    <a href="https://www.centralbank.ie " target="_blank" rel="noreferrer" style={{color:"blue"}}>https://www.centralbank.ie</a>
                                    {language === 'fi_fi' && (<span>.</span>)}
                                </p>

                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
        )
}
