import React, {useEffect} from 'react';
import Layout from '../components/shared/layout'
import FaqComponent from '../components/faq/faq.component'
import { graphql } from 'gatsby'

export default function FaqPage() {
    useEffect(() => {
        document.title = '';
    }, [])
    return (
        <Layout header={true} isBackBtn={true}>
            <FaqComponent></FaqComponent>
        </Layout>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`